
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import {namespace} from "vuex-class";
import {APPLICATION_STORE_NAME, ApplicationStoreActions, ApplicationStoreGetters} from "@/store/application.store";
import {AUTH_STORE_NAME, AuthStoreGetters} from "@/store/auth.store";
import Restaurateur from "@/models/Restaurateur";
import Company from "@/models/Company";
import {LocationStatus} from "@/enum/LocationStatus.enum";
import Address from "@/models/Address";
import {ROUTE_PROFILE} from "@/router/routes";

const ApplicationStore = namespace(APPLICATION_STORE_NAME);
const AuthStore = namespace(AUTH_STORE_NAME);

enum SettingState {
  PERSONAL = 'PERSONAL',
  COMPANY = 'COMPANY',
  PAYMENT = 'PAYMENT'
}

@Component({
  components: {
    EditProfilePersonalComponent: () => import(
      /* webpackChunkName: "EditProfilePersonalComponent" */
      '@/components/Settings/EditPersonalProfile.component.vue'
    ),
    EditProfileCompanyComponent: () => import(
      /* webpackChunkName: "EditProfileCompanyComponent" */
      '@/components/Settings/EditProfileCompany.component.vue'
    ),
    StripeConnectFinishedComponent: () => import(
      /* webpackChunkName: "StripeConnectFinishedComponent" */
      '@/components/Payment/StripeConnectFinished.component.vue'
    ),
    EditCompanyPaymentComponent: () => import(
      /* webpackChunkName: "EditCompanyPaymentComponent" */
      '@/components/Settings/CompanySettings/EditCompanyPayment.component.vue'
    ),
    EditProfilePaymentComponent: () => import(
      /* webpackChunkName: "EditProfilePaymentComponent" */
      '@/components/Settings/EditPaymentProfile.component.vue'
    ),
  },
})
export default class EditProfileView extends mixins(ErrorMessageHandlerMixin) {
  @Prop({default: SettingState.PERSONAL})
  private initialState!: SettingState;

  @AuthStore.Getter(AuthStoreGetters.CURRENT_USER)
  private user!: Restaurateur;

  @ApplicationStore.Action(ApplicationStoreActions.GET_CURRENT_COMPANY)
  private getCompany!: (id: string) => Promise<Company>;

  @ApplicationStore.Getter(ApplicationStoreGetters.CURRENT_COMPANY)
  private company!: Company;

  private currentState = this.initialState;

  private stripeConnectFinishedDialog: boolean = false;

  /**
   * shows badge when the company address status is error or when the delivery address status is error (only when
   * delivery address is completed)
   * @private
   */
  private get showBadge() {
    return this.company.address.locationStatus === LocationStatus.ERROR ||
        (this.company.deliveryAddress?.locationStatus === LocationStatus.ERROR
            && Address.isAddressComplete(this.company.deliveryAddress!));
  }

  private async created() {
    const state = this.$route.query.state as SettingState;
    if(state) {
      this.currentState = state;
    }

    // checks if stripe connect redirect url was used to shows confirm dialog for stripe connect
    const stripeConnectCompleted = this.$route.query.scc;
    if(stripeConnectCompleted === 'true') this.stripeConnectFinishedDialog = true;

    if(!this.company?.id) return;
    await this.getCompany(this.company?.id);
  }

  /**
   * is called when the stripe connect finished dialog should close, closes the dialog and routes back the profile view
   * without the query param
   * @private
   */
  private onStripeConnectDialogDismissed() {
    this.stripeConnectFinishedDialog = false;
    this.$router.replace({name: ROUTE_PROFILE, params: {id: this.$route.params.id}});
  }

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: string): void {
    this.currentState = value as SettingState;
    this.$router.replace({name: ROUTE_PROFILE, params: {id: this.$route.params.id}, query: {'state': this.currentState}});
  }

  private isStateActive(value: string) {
    return this.currentState === value as SettingState;
  }
}
